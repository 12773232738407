import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { SystemQueryOptions } from 'app/core/interfaces/others/system-query-options.interface';
import { CryptService } from '../services/others/crypt.service';
import { IdentificatorsConstants } from '../constants/identificator.constants';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericUtil {
  codec = new HttpUrlEncodingCodec();

  /**
   *
   */
  constructor(private _cryptService: CryptService, protected _router: Router) {}

  generateUrlOdataQuery(uri: string, options: SystemQueryOptions): string {
    let url: string = '';
    let query: string = '';

    if (options.otherParams) {
      query = query.concat(query != '' ? '&' : '', options.otherParams);
    }

    if (options.count) {
      query = query.concat(query != '' ? '&' : '', '$count=true');
    }

    if (options.expand) {
      query = query.concat(query != '' ? '&' : '', '$expand=', options.expand);
    }

    if (options.filter) {
      query = query.concat(query != '' ? '&' : '', '$filter=', options.filter);
    }

    if (options.format) {
      query = query.concat(query != '' ? '&' : '', '$format=', options.format);
    }

    if (options.select) {
      query = query.concat(query != '' ? '&' : '', '$select=', options.select);
    }

    if (options.skip) {
      query = query.concat(query != '' ? '&' : '', '$skip=', options.skip.toString());
    }

    if (options.top || options.top == 0) {
      query = query.concat(query != '' ? '&' : '', '$top=', options.top.toString());
    }

    if (options.orderby) {
      query = query.concat(query != '' ? '&' : '', '$orderby=', options.orderby);
    }

    url = uri;
    if (query != '') {
      url = uri.concat('?', query);
    }

    return url;
  }

  /**
   * Generate random string
   * @param length
   * @returns
   */
  randomString(length): string {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  /**
   * Convert file size from bytes to KB or MB
   * @param fileSize
   * @returns
   */
  convertSize(fileSize: number): string {
    return fileSize < 1024000 ? (fileSize / 1024).toFixed(2) + ' KB' : (fileSize / 1024000).toFixed(2) + ' MB';
  }

  /**
   * Encrypt url parameter
   * @param parameter
   */
  encryptUrlParameter(parameter: string | number): string {
    let encryptedParameter: string = '';
    if (parameter) {
      encryptedParameter = this.codec.encodeValue(this._cryptService.cipher(parameter.toString()));
    }
    return encryptedParameter;
  }

  /**
   * Decrypt url parameter
   * @param parameter
   */
  decryptUrlParameter(parameter: string): string {
    const decryptedParameter = this._cryptService.deCipher(this.codec.decodeValue(parameter));

    if (!decryptedParameter) {
      this._router.navigateByUrl('404-not-found');
    }

    return decryptedParameter;
  }

  /**
   * Return all form errors
   * @param form
   * @returns
   */
  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray ? this.getAllErrors(control) : control?.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  getDaysFromUnitTime(guid: string) {
    let days: number = 0;

    switch (guid) {
      case IdentificatorsConstants.UNIT_DIA_GUID:
        days = 1;

        break;

      case IdentificatorsConstants.UNIT_HORA_GUID:
        days = 1;

        break;

      case IdentificatorsConstants.UNIT_MINUTOS_GUID:
        days = 0;

        break;

      case IdentificatorsConstants.UNIT_SEMANA_GUID:
        days = 7;

        break;

      case IdentificatorsConstants.UNIT_MES_GUID:
        days = 30;

        break;

      case IdentificatorsConstants.UNIT_AÑO_GUID:
        days = 365;

        break;

      default:
        break;
    }

    return days;
  }

  combineDateAndTime(dateStr: string, timeStr: string): Date {
    const date = new Date(dateStr);

    // Extraemos la hora y los minutos del string
    const [hourStr, minuteStr, period] = timeStr.split(/[:\s]+/);
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    // Si es PM y la hora es diferente de 12, añadimos 12 horas (para convertir a formato de 24 horas)
    if (period.toUpperCase() === 'PM' && hour !== 12) {
      hour += 12;
    }

    // Si es AM y la hora es 12, reseteamos la hora a 0
    if (period.toUpperCase() === 'AM' && hour === 12) {
      hour = 0;
    }

    // Actualizamos el objeto Date con la nueva hora y minutos
    date.setHours(hour, minute);

    return date;
  }

  convertToUTCString(date: string): string {
    const localDate = new Date(date);
    return new Date(
      Date.UTC(
        localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        //localDate.getUTCHours() + 5,
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds()
      )
    ).toISOString();
  }

  /*
  convertToUTCStringMinus(date: string): string {
    const localDate = new Date(date)
    return new Date(
      Date.UTC(
        localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours() - 5,
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds(),
      ),
    ).toISOString()
  }
    */

  filterValuesForMatSelect<T>(search: string, properties: string[], source: T[], multi?): ReplaySubject<T[]> {
    const results$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);

    // Si no hay búsqueda, devolver la fuente original
    if (!search) {
      results$.next(source);
      return results$;
    }

    search = search.toLowerCase(); // Convertir a minúsculas para la búsqueda
    const optionsKey = properties[0];
    const nameKey = properties[1];

    const results: T[] = source
      .map((item) => {
        const value: any = item;
        const options = value[optionsKey];

        if (options && Array.isArray(options)) {
          // Filtrar las opciones que coinciden con la búsqueda
          const filteredOptions = options.filter((option) => {
            const nestedKeys = nameKey.split('.');
            let currentValue = option;

            for (const key of nestedKeys) {
              currentValue = currentValue[key];
              if (currentValue === undefined || currentValue === null) break; // Salir si no se encuentra
            }

            return currentValue && currentValue.toLowerCase().includes(search);
          });

          // Si hay opciones filtradas, devolver el objeto con las opciones filtradas
          if (filteredOptions.length > 0) {
            return { ...item, options: filteredOptions };
          }
        }

        return null; // Devolver null si no hay coincidencias
      })
      .filter((item) => item !== null); // Filtrar los nulls

    results$.next(results);
    return results$;
  }
}
