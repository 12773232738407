<div class="app-form-card">

    @if(label){
    <div class="ubication_wrapper">
        <mat-icon class="app-form-icon-info" [svgIcon]="'mat_outline:location_on'">
        </mat-icon>
        <label>{{label}}</label>
    </div>
    }
    <div id="map" class="w-full mb-4"></div>
    @if(control?.errors){
    <mat-error>
        <error-message [control]="control" [label]="label"></error-message>
    </mat-error>
    }



    <ng-container *transloco="let t">


        <ng-template #customDialogModal>
            <div class="overflow-scroll max-h-96">
                <p class="mt-2">{{t('mapBox.warning-modal.description')}} </p>

            </div>

        </ng-template>
    </ng-container>

</div>