<ng-container *transloco="let t">
  <div class="wrapper">
    <div class="custom-map">
      <mat-label>{{ props['label'] }}</mat-label>
      <p class="custom-description">{{ props['description'] }}</p>
      <div class="custom-map__wrapper">
        <app-mapBox [showDrawControl]="false" [showMarkerControl]="true" [placeholderSearch]="t('actions.search')"
          [formModes]="formModes" (markerCoordinates)="setLocationMarker($event)"
          [locationInit]="markerCoordinatesValue.length > 0 ? [ markerCoordinatesValue[0], markerCoordinatesValue[1] ] : []"
          [markerCoordinatesValue]="markerCoordinatesValue"></app-mapBox>
        <mat-error *ngIf="showError">{{ errorMessage }}</mat-error>
      </div>
    </div>
    <app-custom-note (sendText)="getNote($event)" [textValue]="props['note']" (sendImage)="getImage($event)"
      [formDisable]="formControl"></app-custom-note>
  </div>
</ng-container>