import { CaslSubjects } from 'app/core/enums/casl-subjects.enum';
import { IndexCategory } from 'app/core/interfaces/others/index-category.interface';

export const settingsCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Configuración general',
    options: [
      {
        id: CaslSubjects.Positions,
        title: 'Cargos',
        button: 'view',
        description:
          'Define posiciones o roles que ocupan los colaboradores en tu unidad de negocio según las actividades o funciones asignadas.',
        icon: 'mat_outline:account_tree',
        link: ['positions']
      },
      {
        id: CaslSubjects.Customers,
        title: 'Clientes',
        button: 'view',
        description:
          'Administra la información de personas, empresas u organizaciones que adquieren tus productos o servicios.',
        icon: 'mat_outline:supervised_user_circle',
        link: ['customers']
      },
      {
        id: CaslSubjects.Supplier,
        title: 'Proveedores',
        button: 'view',
        description:
          'Gestiona la información de empresas u organizaciones que te suministran productos, servicios o materiales.',
        icon: 'heroicons_outline:shopping-bag',
        link: ['suppliers']
      },
      {
        id: CaslSubjects.Activitytypes,
        title: 'Tipos de labores',
        button: 'view',
        description:
          'Maneja los tipos de labores que realizas dentro de tu unidad productiva y establece la información requerida.',
        icon: 'mat_outline:checklist',
        link: ['activity-types']
      },
      {
        id: CaslSubjects.Collaborators,
        title: 'Colaboradores',
        button: 'view',
        description:
          'Centraliza y organiza la información de tu equipo de trabajo, gestiona datos de contacto, médicos, usuario y contraseña, entre otros.',
        icon: 'mat_outline:people',
        link: ['collaborators']
      },
      {
        id: CaslSubjects.MachineryEquipment,
        title: 'Máquinas, equipos y herramientas',
        button: 'view',
        description:
          'Maneja los elementos que te permiten realizar tareas de manera más eficiente y rápida tales como tractores, bombas, entre otros.',
        icon: 'mat_outline:construction',
        link: ['machinery-equipments']
      }
    ]
  },
  {
    id: 2,
    title: 'Áreas',
    options: [
      {
        id: CaslSubjects.ComplementaryAreas,
        title: 'Áreas complementarias',
        button: 'view',
        description: 'Crea las instalaciones de tu unidad productiva tales como bodegas, laboratorios, entre otros.',
        icon: 'mat_solid:house_siding',
        link: ['complementary-areas']
      },
      {
        id: CaslSubjects.ProductionAreas,
        title: 'Áreas de producción',
        button: 'view',
        description:
          'Especifica las extensiones de tierra de tu unidad productiva utilizadas para actividades agrícolas o pecuarias.',
        icon: 'mat_outline:map',
        link: ['production-areas']
      },
      {
        id: CaslSubjects.WaterSources,
        title: 'Fuentes hídricas',
        button: 'view',
        description:
          'Gestiona las fuentes hídricas en tu unidad productiva. Registra, localiza y analiza la calidad del agua para asegurar un manejo eficiente y sostenible de los recursos hídricos.',
        icon: 'mat_outline:water_drop',
        link: ['water-sources']
      }
    ]
  },
  {
    id: 3,
    title: 'Actividades productivas',
    options: [
      {
        id: CaslSubjects.HarvestProducts,
        title: 'Cultivos',
        button: 'view',
        description:
          'Establece los cultivos o plantas que cosechas en tu unidad productiva y que puedes usar para consumo humano o animal, venta, transformación en productos procesados, entre otros.',
        icon: 'mat_outline:real_estate_agent',
        link: ['harvest-products']
      },
      {
        id: CaslSubjects.AnimalProducts,
        title: 'Animales',
        button: 'view',
        description:
          'Registra las especies animales que crías o manejas en tu unidad productiva, ya sea para su producción, consumo o venta.',
        icon: 'mat_outline:whatshot',
        link: ['farm-animals']
      },
      {
        id: CaslSubjects.Processedproducts,
        title: 'Productos procesados',
        button: 'view',
        description:
          'Define los productos que generas a partir de materias primas agrícolas y procesos de transformación.',
        icon: 'mat_outline:device_hub',
        link: ['processed-products']
      },
      {
        id: CaslSubjects.ByProducts,
        title: 'Subproductos',
        button: 'view',
        description:
          'Configura los productos o materiales que resultan como sobrantes de la producción agrícola o pecuaria y que usas con algún propósito.',
        icon: 'mat_outline:grid_view',
        link: ['byproducts']
      }
    ]
  },
  {
    id: 4,
    title: 'Catálogo de insumos',
    options: [
      {
        id: CaslSubjects.Foods,
        title: 'Alimentos',
        button: 'view',
        description: 'Registra los alimentos que usas para cumplir las necesidades nutricionales de tus animales.',
        icon: 'mat_outline:local_dining',
        link: ['foods']
      },
      {
        id: CaslSubjects.Fertilizers,
        title: 'Fertilizantes',
        button: 'view',
        description:
          'Establece los productos que usas para la fertilidad del suelo y brindar a las plantas los nutrientes necesarios para su desarrollo.',
        icon: 'heroicons_outline:sparkles',
        link: ['fertilizers']
      },
      {
        id: CaslSubjects.Meds,
        title: 'Medicamentos',
        button: 'view',
        description:
          'Ingresa los medicamentos que usas para prevenir, tratar o controlar enfermedades de tus animales.',
        icon: 'mat_outline:medication',
        link: ['meds']
      },
      {
        id: CaslSubjects.Pesticide,
        title: 'Productos fitosanitarios',
        button: 'view',
        description: 'Administra los productos que utilizas para proteger y mejorar la salud de tus plantas o árboles.',
        icon: 'heroicons_outline:beaker',
        link: ['pesticide']
      },
      {
        id: CaslSubjects.SeedsSeedlingsTrees,
        title: 'Semillas, plántulas y árboles',
        button: 'view',
        description: 'Registra las semillas, plántulas y árboles que usas para la siembra y el cultivo de tus plantas.',
        icon: 'mat_outline:nature',
        link: ['seeds-seedlings-trees']
      },
      {
        id: CaslSubjects.OtherSupplies,
        title: 'Otros insumos',
        button: 'view',
        description:
          'Gestiona otros elementos que usas en tus procesos productivos como alambres, combustibles, empaques, entre otros.',
        icon: 'mat_outline:backup_table',
        link: ['other-supplies']
      }
    ]
  },
  {
    id: 6,
    title: 'IoT',
    options: [
      {
        id: CaslSubjects.Devices,
        title: 'Dispositivos',
        button: 'view',
        description:
          'Gestiona dispositivos IoT en tu unidad productiva, asociando áreas y variables de medición para monitoreo en tiempo real.',
        icon: 'mat_outline:bar_chart',
        link: ['devices']
      },
      {
        id: CaslSubjects.AlertSettings,
        title: 'Configuración de alertas',
        button: 'view',
        description:
          'Permite crear y personalizar alertas según los dispositivos y variables, notificando automáticamente cuando se detectan condiciones anómalas en los cultivos o recursos.',
        icon: 'mat_outline:bar_chart',
        link: ['alert-settings']
      }
    ]
  },
  {
    id: 5,
    title: 'Métricas y KPIs',
    options: [
      {
        id: CaslSubjects.Foods,
        title: 'Indicadores',
        button: 'view',
        description:
          'Configura y parametriza los indicadores de desempeño que utilizarás para medir el rendimiento de tu unidad productiva.',
        icon: 'mat_outline:bar_chart',
        link: ['indicator']
      }
    ]
  }
];

export const settingsCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'General setup',
    options: [
      {
        id: CaslSubjects.Positions,
        title: 'Positions',
        button: 'view',
        description:
          'Define positions or roles that employees hold in your business unit according to assigned activities or functions.',
        icon: 'mat_outline:account_tree',
        link: ['positions']
      },
      {
        id: CaslSubjects.Customers,
        title: 'Customers',
        button: 'view',
        description:
          'Manage information about people, companies, or organizations that acquire your products or services.',
        icon: 'mat_outline:supervised_user_circle',
        link: ['customers']
      },
      {
        id: CaslSubjects.Supplier,
        title: 'Suppliers',
        button: 'view',
        description:
          'Manage information about companies or organizations that supply you with products, services, or materials.',
        icon: 'heroicons_outline:shopping-bag',
        link: ['suppliers']
      },
      {
        id: CaslSubjects.Activitytypes,
        title: 'Types of activities',
        button: 'view',
        description:
          'Handle the types of activities you perform within your production unit and establish the required information.',
        icon: 'mat_outline:checklist',
        link: ['activity-types']
      },
      {
        id: CaslSubjects.Collaborators,
        title: 'Staff',
        button: 'view',
        description:
          'Centralize and organize the information of your work team, manage contact, medical, username and password data, among others.',
        icon: 'mat_outline:people',
        link: ['collaborators']
      },
      {
        id: CaslSubjects.MachineryEquipment,
        title: 'Machinery, Equipment and Tools',
        button: 'view',
        description:
          'Manage the elements that allow you to perform tasks more efficiently and quickly such as tractors, pumps, among others.',
        icon: 'mat_outline:construction',
        link: ['machinery-equipments']
      }
    ]
  },
  {
    id: 2,
    title: 'Areas',
    options: [
      {
        id: CaslSubjects.ComplementaryAreas,
        title: 'Complementary areas',
        button: 'view',
        description: 'Create the installations of your production unit such as warehouses, laboratories, among others.',
        icon: 'mat_solid:house_siding',
        link: ['complementary-areas']
      },
      {
        id: CaslSubjects.ProductionAreas,
        title: 'Production areas',
        button: 'view',
        description:
          'Specify the land extensions of your production unit used for agricultural or livestock activities.',
        icon: 'mat_outline:map',
        link: ['production-areas']
      },
      {
        id: CaslSubjects.WaterSources,
        title: 'Water sources',
        button: 'view',
        description:
          'Manage water sources in your productive unit. Register, locate, and analyze water quality to ensure efficient and sustainable water resource management.',
        icon: 'mat_outline:water_drop',
        link: ['water-sources']
      }
    ]
  },
  {
    id: 3,
    title: 'Productive activities',
    options: [
      {
        id: CaslSubjects.HarvestProducts,
        title: 'Crops',
        button: 'view',
        description:
          'Establish the crops or plants that you harvest in your productive unit and that you can use for human or animal consumption, sale, transformation into processed products, among others.',
        icon: 'mat_outline:real_estate_agent',
        link: ['harvest-products']
      },
      {
        id: CaslSubjects.AnimalProducts,
        title: 'Animals',
        button: 'view',
        description:
          'Register the animal species that you breed or manage in your production unit, whether for production, consumption or sale.',
        icon: 'mat_outline:whatshot',
        link: ['farm-animals']
      },
      {
        id: CaslSubjects.Processedproducts,
        title: 'Processed products',
        button: 'view',
        description: 'Define the products you generate from agricultural raw materials and transformation processes.',
        icon: 'mat_outline:device_hub',
        link: ['processed-products']
      },
      {
        id: CaslSubjects.ByProducts,
        title: 'Byproducts',
        button: 'view',
        description:
          'Configure the products or materials that result as surpluses from agricultural or livestock production and that you use for some purpose.',
        icon: 'mat_outline:grid_view',
        link: ['byproducts']
      }
    ]
  },
  {
    id: 4,
    title: 'Supplies catalog',
    options: [
      {
        id: CaslSubjects.Foods,
        title: 'Food',
        button: 'view',
        description: 'Register the foods you use to meet the nutritional needs of your animals.',
        icon: 'mat_outline:local_dining',
        link: ['foods']
      },
      {
        id: CaslSubjects.Fertilizers,
        title: 'Fertilizers',
        button: 'view',
        description:
          'Establish the products you use for soil fertility and provide plants with the necessary nutrients for their development.',
        icon: 'heroicons_outline:sparkles',
        link: ['fertilizers']
      },
      {
        id: CaslSubjects.Meds,
        title: 'Medications',
        button: 'view',
        description: 'Enter the medications you use to prevent, treat, or control diseases of your animals.',
        icon: 'mat_outline:medication',
        link: ['meds']
      },
      {
        id: CaslSubjects.Pesticide,
        title: 'Phytosanitary Products',
        button: 'view',
        description: 'Manage the products you use to protect and improve the health of your plants or trees.',
        icon: 'heroicons_outline:beaker',
        link: ['pesticide']
      },
      {
        id: CaslSubjects.SeedsSeedlingsTrees,
        title: 'Seeds, Seedlings and Trees',
        button: 'view',
        description: 'Register the seeds, seedlings and trees you use for planting and cultivating your plants.',
        icon: 'mat_outline:nature',
        link: ['seeds-seedlings-trees']
      },
      {
        id: CaslSubjects.OtherSupplies,
        title: 'Other Supplies',
        button: 'view',
        description:
          'Manage other elements you use in your production processes such as wires, fuels, packaging, among others.',
        icon: 'mat_outline:backup_table',
        link: ['other-supplies']
      }
    ]
  },
  {
    id: 6,
    title: 'IoT',
    options: [
      {
        id: CaslSubjects.Devices,
        title: 'Devices',
        button: 'view',
        description:
          'Manage IoT devices in your production unit by associating areas and measurement variables for real-time monitoring.',
        icon: 'mat_outline:bar_chart',
        link: ['devices']
      },
      {
        id: CaslSubjects.AlertSettings,
        title: 'Alert settings',
        button: 'view',
        description:
          'It allows you to create and customize alerts based on devices and variables, automatically notifying you when abnormal conditions are detected in crops or resources.',
        icon: 'mat_outline:bar_chart',
        link: ['alert-settings']
      }
    ]
  },
  {
    id: 5,
    title: 'Metrics and KPIs',
    options: [
      {
        id: CaslSubjects.Foods,
        title: 'Indicators',
        button: 'view',
        description:
          'Configure and parameterize the performance indicators that you will use to measure the performance of your production unit.',
        icon: 'mat_outline:bar_chart',
        link: ['indicator']
      }
    ]
  }
];

export const managementCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Management',
    options: [
      {
        id: CaslSubjects.AttachedProducers,
        title: 'Productores',
        button: 'view',
        description:
          'Gestiona a los productores que están afiliados a tu organización. Captura detalles como su nombre, dirección, información de contacto, entre otros.',
        icon: 'heroicons_outline:user-group',
        link: ['attached-producers']
      },
      {
        id: CaslSubjects.AttachedFarms,
        title: 'Fincas',
        button: 'view',
        description:
          'Registra las unidades productivas que están adscritas a tu organización.  Captura detalles como ubicación, tamaño, constitución legal, entre otros.',
        icon: 'mat_outline:cottage',
        link: ['attached-farms']
      }
    ]
  }
];

export const managementCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Management',
    options: [
      {
        id: CaslSubjects.AttachedProducers,
        title: 'Producers',
        button: 'view',
        description:
          'Manage producers who are affiliated with your organization. Capture details such as your name, address, contact information, among others.',
        icon: 'mat_outline:account_tree',
        link: ['attached-producers']
      },
      {
        id: CaslSubjects.AttachedFarms,
        title: 'Farms',
        button: 'view',
        description:
          'Register the productive units that are attached to your organization. Capture details such as location, size, legal constitution, among others.',
        icon: 'mat_outline:supervised_user_circle',
        link: ['attached-farms']
      }
    ]
  }
];

export const planningCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Planning',
    options: [
      {
        id: CaslSubjects.AgriculturalCycles,
        title: 'Ciclos agrícolas',
        button: 'view',
        description:
          'Gestiona información relevante de cada ciclo, como el cultivo, la variedad, el área cultivada, las fechas de inicio y finalización, así como realizar un seguimiento de las actividades y labores realizadas en cada etapa.',
        icon: 'mat_outline:wb_sunny',
        link: ['agricultural-cycles']
      },
      /*
      {
        id: CaslSubjects.BatchesOfBovinoesAndPigs,
        title: 'Lotes de animales',
        button: 'view',
        description:
          'Registra y has seguimiento de los datos relacionados con cada lote de animales de tu unidad productiva, como el tipo de animal, la raza, el número de animales, la fecha de ingreso, el estado de salud, la alimentación, entre otros.',
        icon: 'mat_outline:grid_on',
        link: ['batches-of-bovinoes-and-pigs']
      },
      */
      {
        id: CaslSubjects.Activities,
        title: 'Labores',
        button: 'view',
        description:
          'Crea planes de trabajo, asigna responsabilidades y has seguimiento de las labores planificadas. Ingresa y consulta información sobre cada actividad, como la descripción, la fecha programada, la duración estimada y los recursos necesarios.',
        icon: 'heroicons_outline:check-circle',
        link: ['activities']
      }
    ]
  }
];

export const planningCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Planning',
    options: [
      {
        id: CaslSubjects.AgriculturalCycles,
        title: 'Agricultural cycles',
        button: 'view',
        description:
          'Manage relevant information for each cycle, such as the crop, variety, cultivated area, start and end dates, as well as keep track of the activities and tasks carried out at each stage.',
        icon: 'mat_outline:wb_sunny',
        link: ['agricultural-cycles']
      },
      {
        id: CaslSubjects.BatchesOfBovinoesAndPigs,
        title: 'Animal batches',
        button: 'view',
        description:
          'Record and track the data related to each batch of animals in your production unit, such as the type of animal, breed, number of animals, date of entry, health status, food, among others.',
        icon: 'mat_outline:grid_on',
        link: ['batches-of-bovinoes-and-pigs']
      },
      {
        id: CaslSubjects.Activities,
        title: 'Activities',
        button: 'view',
        description:
          'Create work plans, assign responsibilities and follow up on planned tasks. Enter and view information about each activity, such as the description, scheduled date, estimated duration, and required resources.',
        icon: 'heroicons_outline:check-circle',
        link: ['activities']
      }
    ]
  }
];

export const inventoryCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Inventario',
    options: [
      {
        id: CaslSubjects.Inputs,
        title: 'Entradas',
        button: 'view',
        description:
          'Registra todas las entradas de productos al inventario de tu unidad productiva, como compras de insumos, recepción de donaciones o préstamos, entre otros.',
        icon: 'mat_outline:shopping_cart',
        link: ['inputs']
      },
      {
        id: CaslSubjects.Outputs,
        title: 'Salidas',
        button: 'view',
        description:
          'Registra todas las salidas de productos del inventario tu unidad productiva, como ventas, perdidas o desperdicios, alquiler, entre otros.',
        icon: 'heroicons_outline:shopping-bag',
        link: ['outputs']
      },
      {
        id: CaslSubjects.Stock,
        title: 'Existencias',
        button: 'view',
        description:
          'Mantiene la información actualizada de la cantidad de cada producto disponible en el inventario. Permitiendo así, tener un control eficiente y preciso de la existencia de los productos en todo momento.',
        icon: 'mat_outline:analytics',
        link: ['stock']
      }
    ]
  }
];

export const inventoryCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Inventory',
    options: [
      {
        id: CaslSubjects.Inputs,
        title: 'Receipts',
        button: 'view',
        description:
          'Registers all product entries to the inventory of your production unit, such as purchases of inputs, receipt of donations or loans, among others.',
        icon: 'mat_outline:shopping_cart',
        link: ['inputs']
      },
      {
        id: CaslSubjects.Outputs,
        title: 'Outputs',
        button: 'view',
        description: `Registers all product outputs of your production unit's inventory, such as sales, losses or waste, rent, among others.`,
        icon: 'heroicons_outline:shopping-bag',
        link: ['outputs']
      },
      {
        id: CaslSubjects.Stock,
        title: 'Stock',
        button: 'view',
        description:
          'Maintains updated information on the quantity of each product available in inventory. This allows to have an efficient and accurate control of the stock of products at all times.',
        icon: 'mat_outline:analytics',
        link: ['stock']
      }
    ]
  }
];

export const inventoryManagementEs: IndexCategory[] = [
  {
    id: 1,
    title: 'stock',
    options: [
      {
        id: CaslSubjects.Stock,
        title: 'Alimentos',
        button: 'view',
        description:
          'Consulta y gestiona los stocks de granos, forrajes, suplementos y otros alimentos para animales, mostrando las existencias actuales en diversas bodegas.',
        icon: 'mat_outline:local_dining',
        link: [
          'view/biy?l77=f.%264k6$s9cb,e)0z7C3.ei5?07-=c.24165s-c4,e)6z2C-.ai6?b7f=-.94465sac1,8)2zaCc.bif?977=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9ca,l)izmCe.nit?o7s=7.Q4%5E6usYcm,'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Fertilizantes',
        button: 'view',
        description:
          'Accede a la disponibilidad actualizada y stock mínimo de fertilizantes en todas las bodegas, crucial para la nutrición del suelo.',
        icon: 'heroicons_outline:sparkles',
        link: [
          'view/biy?l77=f.%264k6$s9ca,2)7z1Cc.1i6?27-=d.b4060s-c4,e)dz4C-.bia?07c=-.74567s5cf,4)2zeCc.5i9?877=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cf,e)rztCi.lii?z7a=n.t4e67sQc%5E,u)YzmC'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Medicamentos',
        button: 'view',
        description:
          'Visualiza las existencias actuales y stocks críticos de medicamentos veterinarios y productos de salud animal en el inventario.',
        icon: 'mat_outline:medication',
        link: [
          'view/biy?l77=f.%264k6$s9cc,c)1zbC9.7i3?37-=1.6456fs-c4,4)8z2C-.ai4?b7a=-.f4766s8c8,f)fz6C2.0i0?277=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cm,e)dziCc.aim?e7n=t.o4s67sQc%5E,u)YzmC'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Productos fitosanitarios',
        button: 'view',
        description:
          'Verifica las cantidades disponibles y los stocks mínimos de productos cosechados, incluyendo frutas, verduras, cereales y hortalizas.',
        icon: 'heroicons_outline:beaker',
        link: [
          'view/biy?l77=f.%264k6$s9cd,d)ezaC1.6i1?37-=7.e436as-c4,8)fz3C-.ai2?077=-.34768s8ce,f)0z1Ce.fif?977=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,r)ozdCu.cit?o7s=%20.f4i6tsocs,a)nziCt.air?i7o=s.74Q6%5EsucY,m)'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Productos agrícolas',
        button: 'view',
        description:
          'Verifica las cantidades disponibles y los stocks mínimos de productos cosechados, incluyendo frutas, verduras, cereales y hortalizas.',
        icon: 'mat_outline:checklist',
        link: [
          'view/biy?l77=f.%264k6$s9c0,c)az5Cb.0ia?a7-=5.c4d6bs-c4,a)5zeC-.8ib?37d=-.24969secd,1)az9Ce.0i2?777=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,r)ozdCu.cit?o7%20=a.g4r6%C3%ADscco,l)az7CQ.%5Eiu?Y7m='
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Productos pecuarios',
        button: 'view',
        description:
          'Mantén un seguimiento detallado de los stocks de productos pecuarios como leche y huevos, esenciales para la producción y comercialización.',
        icon: 'mat_solid:house_siding',
        link: [
          'view/biy?l77=f.%264k6$s9c8,3)az2C5.1ib?97-=3.04069s-c4,d)czdC-.9i2?b71=-.a436bs4c2,c)7z1C3.7ic?077=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,r)ozdCu.cit?o7%20=p.e4c6usacr,i)oz7CQ.%5Eiu?Y7m='
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Productos procesados',
        button: 'view',
        description:
          'Accede y administra el inventario actual y stock mínimo de alimentos enlatados y otros productos procesados disponibles en bodegas.',
        icon: 'mat_outline:map',
        link: [
          'view/biy?l77=f.%264k6$s9c8,0)bz7Cc.fia?27-=c.8456cs-c4,8)dz3C-.9i1?677=-.e4560sdcd,7)cz8C1.4i5?e77=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,r)ozdCu.cit?o7%20=p.r4o6csecs,a)dzoC7.Qi%5E?u7Y=m.'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Semillas, plántulas y árboles',
        button: 'view',
        description:
          'Consulta y gestiona la disponibilidad de semillas, plántulas y árboles para siembra, cruciales para la planificación de tus cultivos.',
        icon: 'mat_outline:nature',
        link: [
          'view/biy?l77=f.%264k6$s9cb,a)2zbC3.3i3?27-=7.b4d60s-c4,0)6z6C-.8ib?079=-.0446ds3c2,d)3z4C2.4i1?477=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cs,e)mziCl.lia?,7%20=p.l4%C3%A16nstcu,l)az%20Cy.%20i%C3%A1?r7b=o.l476Qs%5Ecu,Y)mz'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Subproductos',
        button: 'view',
        description:
          'Visualiza y controla los stocks y necesidades de diversos insumos necesarios para las operaciones productivas y comerciales agrícolas.',
        icon: 'mat_outline:whatshot',
        link: [
          'view/biy?l77=f.%264k6$s9c1,f)5zeC1.0i9?e7-=6.0466as-c4,b)9zdC-.bi8?070=-.a426esdc3,6)9z1Cc.ci5?b77=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cs,u)bzpCr.oid?u7c=t.o476Qs%5Ecu,Y)mz'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Otros insumos',
        button: 'view',
        description:
          'Control de todos los demás insumos necesarios para la producción, procesamiento, comercialización, y demás actividades que se requieren realizar en la unidad productiva.',
        icon: 'mat_outline:backup_table',
        link: [
          'view/biy?l77=f.%264k6$s9ca,a)fzbC3.4i7?e7-=9.44a6ds-c4,e)1zcC-.bi1?07c=-.74f63s2c1,9)cz7Ca.9if?277=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9co,t)rzoCs.%20ii?n7s=u.m4o6ss7cQ,%5E)uzYCm.'
        ]
      }
    ]
  }
];

export const inventoryManagementEn: IndexCategory[] = [
  {
    id: 1,
    title: 'management-inventory',
    options: [
      {
        id: CaslSubjects.Stock,
        title: 'Foods',
        button: 'view',
        description:
          'Check and manage stock levels of grains, forages, supplements, and other animal feeds, showing current inventories across various warehouses.',
        icon: 'mat_outline:local_dining',
        link: [
          'view/biy?l77=f.%264k6$s9cb,e)0z7C3.ei5?07-=c.24165s-c4,e)6z2C-.ai6?b7f=-.94465sac1,8)2zaCc.bif?977=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9ca,l)izmCe.nit?o7s=7.Q4%5E6usYcm,'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Fertilizers',
        button: 'view',
        description:
          'Access updated availability and minimum stock levels of fertilizers in all warehouses, crucial for soil nutrition.',
        icon: 'heroicons_outline:sparkles',
        link: [
          'view/biy?l77=f.%264k6$s9ca,2)7z1Cc.1i6?27-=d.b4060s-c4,e)dz4C-.bia?07c=-.74567s5cf,4)2zeCc.5i9?877=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cf,e)rztCi.lii?z7e=r.74Q6%5EsucY,m)'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Medications',
        button: 'view',
        description:
          'View current inventories and critical stock levels of veterinary medicines and animal health products in the inventory.',
        icon: 'mat_outline:medication',
        link: [
          'view/biy?l77=f.%264k6$s9cc,c)1zbC9.7i3?37-=1.6456fs-c4,4)8z2C-.ai4?b7a=-.f4766s8c8,f)fz6C2.0i0?277=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cm,e)dziCc.ait?i7o=n.s476Qs%5Ecu,Y)mz'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Phytosanitary products',
        button: 'view',
        description:
          'Control and review the stocks of phytosanitary products used for efficient pest and disease management in your crops.',
        icon: 'heroicons_outline:beaker',
        link: [
          'view/biy?l77=f.%264k6$s9cd,d)ezaC1.6i1?37-=7.e436as-c4,8)fz3C-.ai2?077=-.34768s8ce,f)0z1Ce.fif?977=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,h)yztCo.sia?n7i=t.a4r6ys%20cp,r)ozdCu.cit?s77=Q.%5E4u6Ysmc'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Agricultural products',
        button: 'view',
        description:
          'Verify available quantities and minimum stocks of harvested products, including fruits, vegetables, cereals, and vegetables.',
        icon: 'mat_outline:checklist',
        link: [
          'view/biy?l77=f.%264k6$s9c0,c)az5Cb.0ia?a7-=5.c4d6bs-c4,a)5zeC-.8ib?37d=-.24969secd,1)az9Ce.0i2?777=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9ca,g)rziCc.uil?t7u=r.a4l6%20spcr,o)dzuCc.ti7?Q7%5E=u.Y4m6'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Livestock products',
        button: 'view',
        description:
          'Keep detailed track of livestock product stocks like milk and eggs, essential for production and marketing.',
        icon: 'mat_solid:house_siding',
        link: [
          'view/biy?l77=f.%264k6$s9c8,3)az2C5.1ib?97-=3.04069s-c4,d)czdC-.9i2?b71=-.a436bs4c2,c)7z1C3.7ic?077=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cl,i)vzeCs.tio?c7k=%20.p4r6osdcu,c)tz7CQ.%5Eiu?Y7m='
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Processed products',
        button: 'view',
        description:
          'Access and manage the current inventory and minimum stock of canned foods and other processed products available in warehouses.',
        icon: 'mat_outline:map',
        link: [
          'view/biy?l77=f.%264k6$s9c8,0)bz7Cc.fia?27-=c.8456cs-c4,8)dz3C-.9i1?677=-.e4560sdcd,7)cz8C1.4i5?e77=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cp,r)ozcCe.sis?e7d=%20.p4r6osdcu,c)tz7CQ.%5Eiu?Y7m='
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Seeds, seedlings, and trees',
        button: 'view',
        description:
          'Check and manage the availability of seeds, seedlings, and trees for planting, crucial for your crop planning.',
        icon: 'mat_outline:nature',
        link: [
          'view/biy?l77=f.%264k6$s9cb,a)2zbC3.3i3?27-=7.b4d60s-c4,0)6z6C-.8ib?079=-.0446ds3c2,d)3z4C2.4i1?477=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cs,e)ezdC,.%20is?e7e=d.l4i6nsgc%20,a)nzdC%20.tir?e7e=7.Q4%5E6usYcm,'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'By-products',
        button: 'view',
        description:
          'Review stocks and manage the availability of organic waste and other by-products generated in agricultural or livestock production.',
        icon: 'mat_outline:whatshot',
        link: [
          'view/biy?l77=f.%264k6$s9c1,f)5zeC1.0i9?e7-=6.0466as-c4,b)9zdC-.bi8?070=-.a426esdc3,6)9z1Cc.ci5?b77=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9cb,y)-zpCr.oid?u7c=t.74Q6%5EsucY,m)'
        ]
      },
      {
        id: CaslSubjects.Stock,
        title: 'Other supplies',
        button: 'view',
        description:
          'View and control the stocks and needs of various inputs necessary for agricultural production and commercial operations.',
        icon: 'mat_outline:backup_table',
        link: [
          'view/biy?l77=f.%264k6$s9ca,a)fzbC3.4i7?e7-=9.44a6ds-c4,e)1zcC-.bi1?07c=-.74f63s2c1,9)cz7Ca.9if?277=Q.%5E4u6Ysmc/biy?l77=f.%264k6$s9co,t)hzeCr.%20is?u7p=p.l4i6essc7,Q)%5EzuCY.mi'
        ]
      }
    ]
  }
];

export const reportsCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Gestión y administración',
    options: [
      {
        id: CaslSubjects.activityReport,
        title: 'Reporte de labores',
        button: 'view',
        description:
          'Visualiza de forma detallada las labores realizadas en un período específico. Facilitando el seguimiento, la evaluación y la planificación de labores futuras.',
        icon: 'mat_outline:checklist',
        link: ['activities']
      },
      ,
      {
        id: CaslSubjects.attachedProducerReport,
        title: 'Productores adscritos',
        button: 'view',
        description:
          'Obtén una visión completa y detallada de la información relevante sobre cada productor adscrito y sus fincas.',
        icon: 'mat_outline:people',
        link: ['attached-producer']
      }
    ]
  },
  {
    id: 2,
    title: 'Inventario',
    options: [
      {
        id: CaslSubjects.MinimumInventory,
        title: 'Inventario mínimo',
        button: 'view',
        description:
          'Encuentra productos con inventario bajo el mínimo requerido. Asegura pedidos oportunos y evita interrupciones en producción y ventas.',
        icon: 'mat_outline:inventory',
        link: ['inventory/no-minimun-stock']
      },

      {
        id: CaslSubjects.PurchaseHistory,
        title: 'Movimiento de inventario',
        button: 'view',
        description:
          'Obtén un registro detallado de todas las entradas y salidas de productos durante un periodo especifico.',
        icon: 'compare_arrows',
        link: ['inventory/movements']
      },
      {
        id: CaslSubjects.sellingusedproducts,
        title: 'Productos más vendidos / utilizados',
        button: 'view',
        description:
          'Obtén los productos más vendidos o utilizados. Ayuda a entender tendencias de consumo y a tomar decisiones informadas sobre compras y marketing.',
        icon: 'mat_outline:trending_up',
        link: ['inventory/highest-outputs']
      },
      {
        id: CaslSubjects.PurchaseHistory,
        title: 'Valor de inventario',
        button: 'view',
        description:
          'Visualiza el valor del inventario en una fecha especifica. Útil para planificación financiera y toma de decisiones estratégicas.',
        icon: 'feather:dollar-sign',
        link: ['inventory/values']
      }
    ]
  },
  {
    id: 3,
    title: 'Producción',
    options: []
  },
  {
    id: 4,
    title: 'Recursos humanos',
    options: [
      {
        id: CaslSubjects.CollaboratorResume,
        title: 'Reporte de colaboradores',
        button: 'view',
        description:
          'Obtén una visión completa y detallada de la información relevante sobre cada uno de los colaboradores que trabajan en tu unidad productiva.',
        icon: 'heroicons_solid:user-group',
        link: ['collaborators']
      }
    ]
  }
];

export const reportsCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Management and Administration',
    options: [
      {
        id: CaslSubjects.activityReport,
        title: 'Activities report',
        button: 'view',
        description:
          'View in detail the activities performed within a specific period. Facilitating tracking, evaluation, and future planning of tasks.',
        icon: 'mat_outline:checklist',
        link: ['activities']
      },
      {
        id: CaslSubjects.attachedProducerReport,
        title: 'Attached producer',
        button: 'view',
        description:
          'Get a comprehensive and detailed view of the relevant information about each registered producer and their farms.',
        icon: 'mat_outline:people',
        link: ['attached-producer']
      }
    ]
  },
  {
    id: 2,
    title: 'Inventory',
    options: [
      {
        id: CaslSubjects.sellingusedproducts,
        title: 'Best Selling / Used Products',
        button: 'view',
        description:
          'Get the best selling or used products. Helps understand consumer trends and make informed purchasing and marketing decisions.',
        icon: 'mat_outline:trending_up',
        link: ['inventory/highest-outputs']
      },

      {
        id: CaslSubjects.PurchaseHistory,
        title: 'Inventory movement',
        button: 'view',
        description: 'Get a detailed record of all product entries and exits during a specific period.',
        icon: 'compare_arrows',
        link: ['inventory/movements']
      },

      {
        id: CaslSubjects.PurchaseHistory,
        title: 'Inventory values',
        button: 'view',
        description:
          'View the inventory value on a specific date. Useful for financial planning and strategic decision-making.',
        icon: 'feather:dollar-sign',
        link: ['inventory/values']
      },
      {
        id: CaslSubjects.MinimumInventory,
        title: 'Minimum inventory',
        button: 'view',
        description:
          'Find products with inventory below the minimum required. Ensures timely orders and avoids interruptions in production and sales.',
        icon: 'mat_outline:inventory',
        link: ['inventory/no-minimun-stock']
      }
    ]
  },
  {
    id: 3,
    title: 'Production',
    options: []
  },
  {
    id: 4,
    title: 'Human resources',
    options: [
      {
        id: CaslSubjects.CollaboratorResume,
        title: 'Collaborators report',
        button: 'view',
        description:
          'Get a comprehensive and detailed view of relevant information about each of the collaborators working in your productive unit.',
        icon: 'heroicons_solid:user-group',
        link: ['collaborators']
      }
    ]
  }
];

export const analyticsCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Analytics',
    options: [
      {
        id: CaslSubjects.Reports,
        title: 'Reportes',
        button: 'view',
        description:
          'Genera informes detallados y personalizados sobre diferentes aspectos de tu unidad productiva, como el estado del inventario, registros de producción, análisis de ventas, entre otros.',
        icon: 'heroicons_outline:document-text',
        link: ['reports']
      }
      /*
      {
        id: CaslSubjects.ViewIndicators,
        title: 'Indicadores',
        button: 'view',
        description:
          'Visualiza de manera clara los indicadores clave de rendimiento (KPIs) de tu unidad productiva. Los indicadores pueden incluir métricas relacionadas con la producción, ventas, inventario, entre otros.',
        icon: 'show_chart',
        link: ['indicators']
      }
      */
    ]
  }
];

export const analyticsCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Analytics',
    options: [
      {
        id: CaslSubjects.Reports,
        title: 'Reports',
        button: 'view',
        description:
          'Generate detailed and personalized reports on different aspects of your production unit, such as inventory status, production records, sales analysis, among others.',
        icon: 'heroicons_outline:document-text',
        link: ['reports']
      }
      /*
      {
        id: CaslSubjects.ViewIndicators,
        title: 'Indicators',
        button: 'view',
        description:
          'Clearly visualize the key performance indicators (KPIs) of your production unit. The indicators can include metrics related to production, sales, inventory, among others.',
        icon: 'show_chart',
        link: ['indicators']
      }
      */
    ]
  }
];

export const staffCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Analytics',
    options: [
      {
        id: CaslSubjects.Collaborators,
        title: 'Administrar',
        button: 'view',
        description:
          'Centraliza y organiza la información de tu equipo de trabajo, gestiona datos de contacto, médicos, usuario y contraseña, entre otros.',
        icon: 'manage_accounts',
        link: ['manage']
      }
    ]
  }
];

export const staffCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Collaborators',
    options: [
      {
        id: CaslSubjects.Collaborators,
        title: 'Manage',
        button: 'view',
        description:
          'Centralize and organize the information of your work team, manage contact, medical, username and password data, among others.',
        icon: 'manage_accounts',
        link: ['collaborators']
      }
    ]
  }
];

export const certificationsCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'Certificaciones',
    options: [
      {
        id: CaslSubjects.Simulations,
        title: 'Simulaciones',
        button: 'view',
        description: 'Configura y parametriza las simulaciones',
        icon: 'mat_outline:bar_chart',
        link: ['simulations']
      },
      {
        id: CaslSubjects.CertificationManagement,
        title: 'Gestión de certificaciones',
        button: 'view',
        description: 'Gestiona y parametriza las certificaciones',
        icon: 'mat_outline:backup_table',
        link: ['certification-management']
      }
    ]
  }
];

export const certificationsCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'Certification',
    options: [
      {
        id: CaslSubjects.Simulations,
        title: 'Simulations',
        button: 'view',
        description: 'Configure and parameterize the simulations',
        icon: 'mat_outline:bar_chart',
        link: ['simulations']
      },
      {
        id: CaslSubjects.CertificationManagement,
        title: 'Certification Management',
        button: 'view',
        description: 'Manage and parameterize the certifications',
        icon: 'mat_outline:backup_table',
        link: ['certification-management']
      }
    ]
  }
];

export const IoTCategoriesEs: IndexCategory[] = [
  {
    id: 1,
    title: 'IoT',
    options: [
      {
        id: CaslSubjects.DashboardIOT,
        title: 'Tablero IoT',
        button: 'view',
        description:
          'Visualiza en tiempo real los datos de tus dispositivos IoT, monitorea variables de medición y recibe alertas de condiciones anómalas.',
        icon: 'mat_outline:dashboard',
        link: ['dashboard-iot']
      },
      {
        id: CaslSubjects.Alerts,
        title: 'Alertas',
        button: 'view',
        description: 'Gestiona las alertas de los dispositivos IoT en tu unidad productiva.',
        icon: 'mat_outline:bar_chart',
        link: ['alerts']
      }
    ]
  }
];

export const IoTCategoriesEn: IndexCategory[] = [
  {
    id: 1,
    title: 'IoT',
    options: [
      {
        id: CaslSubjects.DashboardIOT,
        title: 'Dashboard IoT',
        button: 'view',
        description:
          'Visualize real-time data from your IoT devices, monitor measurement variables, and receive alerts for abnormal conditions.',
        icon: 'mat_outline:dashboard',
        link: ['dashboard-iot']
      },
      {
        id: CaslSubjects.Alerts,
        title: 'Alertas',
        button: 'view',
        description: 'Manage IoT device alerts in your production unit.',
        icon: 'mat_outline:bar_chart',
        link: ['alerts']
      }
    ]
  }
];
